<template>
  <b-container fluid>
    <b-row class="mb-4 justify-content-between">
      <b-col cols="12" xl="4" md="6" class="mb-2">
        <div class="input-group">
          <span class="input-group-text input-group-text--addon-left">
            <b-icon icon="search"></b-icon>
          </span>
          <input
            v-model="searchQuery"
            type="text"
            class="form-control form-control--addon-left form-control--h46px pl-48px mw-324px rounded-10px"
            placeholder="按標題搜尋"
          />
        </div>
      </b-col>
      <b-col cols="12" xl="2" md="6" class="mb-2">
        <router-link
          to="/loyalty-programs/create"
          class="btn btn-secondary btn--h46px justify-content-center w-100"
        >
          新創建
        </router-link>
      </b-col>
    </b-row>
    <TableData :items="paginatedData" :headers="headers" :isLoading="isLoading">
      <template v-slot:custom-imageUrl="{ item }">
        <img
          class="rounded-circle"
          width="66"
          height="66"
          v-bind:src="item.imageURL"
        />
      </template>
      <template v-slot:custom-couponDiscountPercentage="{ item }">
        <span v-if="item.isPercentage">{{ item.couponDiscountPercentage }}%</span>
        <span v-else> - </span>
      </template>
      <template v-slot:custom-isExpired="{ item }">
          <div v-if="!item.isExpired" class="text-success">啟用</div>
          <div v-else class="text-danger">停用</div>
      </template>
      <template v-slot:custom-createdDate="{ item }">
        {{ item.createdDate | formatDate }}
      </template>
      <template v-slot:custom-action="{ item }">
        <b-dropdown
          variant="link"
          toggle-class="text-white text-decoration-none"
          no-caret
        >
          <template #button-content>
            <b-icon
              icon="three-dots"
              font-scale="1.5"
              class="text-dark"
            ></b-icon>
          </template>
          <b-dropdown-item
            :to="'/loyalty-programs/' + item.id + '/coupon-details'"
          >
            優惠券詳情
          </b-dropdown-item>
          <b-dropdown-item :to="'/loyalty-programs/customers/' + item.id + '/offer'">
            向客戶提供
          </b-dropdown-item>
          <b-dropdown-item :to="'/loyalty-programs/customers/' + item.id + '/offer-for-birthdays'">
            本月生日優惠
          </b-dropdown-item>
          <b-dropdown-item :to="'/loyalty-programs/customers/' + item.id + '/history'">
            已提供客戶
          </b-dropdown-item>
          <b-dropdown-item :to="`/loyalty-programs/customers/${ item.id }/customer-using-coupon`">
            已使用的客戶
          </b-dropdown-item>
          <b-dropdown-item @click="showExpireCouponModal(item.id)">
            停用
          </b-dropdown-item>
        </b-dropdown>
      </template>
    </TableData>
    <Pagination
      v-if="filterCoupons.length && !isLoading"
      v-model="currentPage"
      :list-data="filterCoupons"
      :limit="limit"
    />
    <b-modal
      id="expireCouponModal"
      centered
      size="md"
      no-close-on-backdrop
      hide-footer
      hide-header
    >
      <b-form class="p-3" @submit.prevent="onExpireCoupon">
        <h5 class="text-brown mb-4 text-center">您確定禁用此優惠券嗎？</h5>
        <br>
        <p> 給予免責聲明：對於所有尚未使用優惠券的客戶來說，優惠券將過期</p>
        <br>
        <div class="d-flex mt-4 align-items-center justify-content-between">
          <b-button
            @click="hideExpireCouponModal()"
            class="btn btn-warning rounded-10px px-3 px-lg-5 text-white mr-2 me-lg-4"
            >關閉</b-button
          >
          <b-button
            :disabled="isSubmitting"
            type="submit"
            variant="primary"
            class="rounded-10px px-3 px-lg-5"
            > <b-spinner v-if="isSubmitting" small class="mr-2"></b-spinner>確認</b-button
          >
        </div>
      </b-form>
    </b-modal>
  </b-container>
</template>

<script>
import couponService from '../services/coupon.service'
import Pagination from '../components/Pagination.vue'
import TableData from '../components/TableData.vue'
export default {
  name: 'LoyaltyPrograms',
  data: () => ({
    coupons: [],
    searchQuery: '',
    currentPage: 1,
    limit: 10,
    headers: [
      { text: '優惠券編號', value: 'id' },
      { text: '相片', value: 'imageUrl' },
      { text: '標題', value: 'titleEn' },
      { text: '標題 (繁)', value: 'titleZh' },
      { text: '百分比 (%)', value: 'couponDiscountPercentage' },
      { text: '固定金額 (HKD)', value: 'couponDiscountValue' },
      { text: '有效期限天數', value: 'validityPeriodDays' },
      { text: '狀態', value: 'isExpired' },
      { text: '創建日期', value: 'createdDate' },
      { text: '操作', value: 'action' }
    ],
    isLoading: false,
    selectedId: 0,
    isSubmitting: false
  }),
  components: {
    Pagination,
    TableData
  },
  mounted () {
    this.getAllCoupons()
  },
  computed: {
    filterCoupons () {
      return this.coupons.filter((item) =>
        this.searchQuery.length
          ? item.titleEn
            .toLowerCase()
            .replace(/\s+/g, '')
            .includes(this.searchQuery.toLowerCase().replace(/\s+/g, '')) ||
            item.titleZh
              .toLowerCase()
              .replace(/\s+/g, '')
              .includes(this.searchQuery.toLowerCase().replace(/\s+/g, ''))
          : true
      )
    },
    paginatedData () {
      return this.filterCoupons.slice(
        (this.currentPage - 1) * this.limit,
        this.currentPage * this.limit
      )
    }
  },
  watch: {
    filterCoupons () {
      this.currentPage = 1
    }
  },
  methods: {
    async showExpireCouponModal (id) {
      this.selectedId = id
      this.$bvModal.show('expireCouponModal')
    },
    async hideExpireCouponModal () {
      this.selectedId = 0
      this.$bvModal.hide('expireCouponModal')
    },
    async getAllCoupons () {
      this.isLoading = true
      await couponService.getAllCoupons().then(
        (res) => {
          this.coupons = res.data
        },
        (err) => {
          this.makeToast('danger', '發生錯誤，請稍後再試。', err)
        }
      )
      this.isLoading = false
    },
    makeToast (variant = null, title = 'Title', message = 'Message!') {
      this.$bvToast.toast(message, {
        title: title,
        autoHideDelay: 3000,
        variant: variant,
        solid: true
      })
    },
    async onExpireCoupon () {
      this.isSubmitting = true
      await couponService.expireCoupon(this.selectedId).then(
        (res) => {
          if (res.success) {
            this.getAllCoupons()
            this.makeToast('success', '優惠券管理', '停用成功')
          }
        },
        (err) => {
          this.makeToast('danger', '發生錯誤，請稍後再試。', err)
        }
      )
      this.hideExpireCouponModal()
      this.isSubmitting = false
    }
  }
}
</script>
